<template>
  <div>
    <PageTitle
      icon="mdi-account"
      :title="
        `${$t('global.application')} ${jobApplicationId} - ${$t('global.activities')}`
      "
      back-button
      print-button
    ></PageTitle>

    <v-row>

      <v-col class="pt-0">
        <ActivitiesHeader
          v-if="jobApplication"
          :with-lock-icon="disabled"
          :title="`${candidate?.first_name} ${candidate?.last_name}`"
          :subtitle="activitiesSubtitle"
        />

        <v-card class="mt-6 pa-8 print-no-spacing">
          <v-row no-gutters class="mb-5 d-print-none">
            <v-col>
              <ComponentTitleWithDataLoader></ComponentTitleWithDataLoader>
            </v-col>
            <v-spacer />
            <v-col class="text-right">
              <EditActivitiesDialog
                :previous-selection="jobApplicationActivitySelections"
                :disabled="disabled"
                @addAndRemoveActivitySelections="addAndRemoveActivitySelections"
              />
            </v-col>
          </v-row>
          <!-- Don't use a loader here or subcomponents states are wrong when the page is heavy -->
          <ExplicitActivitiesTable
            :activity-selections="jobApplicationActivitySelections"
            :variant="Variant.JobApplication"
            :disabled="disabled"
            @removeSelection="removeSelection"
            @change="refreshJobApplication"
          >
            <template v-slot:interestColumn="{ item }">
              <Interest :selection="item" :disabled="disabled"></Interest>
            </template>

            <template v-slot:actionsColumn="{ item }">
              <DeleteActivityDialog
                :selection="item"
                :disabled="disabled"
                @removeSelection="removeSelection"
              ></DeleteActivityDialog>
            </template>

          </ExplicitActivitiesTable>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EditActivitiesDialog from '@/components/activities/EditActivitiesDialog.vue'
import ExplicitActivitiesTable, {
  Variant
} from '@/components/activities/ExplicitActivitiesTable.vue'
import eventBus from '@/eventBus'
import Interest from '@/components/activities/Interest.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'
import DeleteActivityDialog from '@/components/activities/DeleteActivityDialog.vue'
import ActivitiesHeader from '@/components/common/ActivitiesHeader.vue'

export default {
  name: 'JobApplicationActivities',
  components: {
    ActivitiesHeader,
    DeleteActivityDialog,
    ComponentTitleWithDataLoader,
    PageTitle,
    Interest,
    ExplicitActivitiesTable,
    EditActivitiesDialog
  },
  data() {
    return {
      Variant
    }
  },
  computed: {
    ...mapState('candidates', ['candidate']),
    ...mapState('jobApplications', ['jobApplication']),
    ...mapState('professions', ['professions']),
    ...mapGetters('page', ['dataIsLoading']),
    jobApplicationId() {
      return this.$route.params.id
    },
    candidateFullName() {
      return this.candidate
        ? ` - ${this.candidate.first_name} ${this.candidate.last_name}`
        : ''
    },
    jobApplicationActivitySelections() {
      return (this.jobApplication) ? this.jobApplication.selections : [];
    },
    disabled() {
      return this.jobApplication?.status[0] === "MATCHING" || this.jobApplication?.status[0] === "ARCHIVED"
    },
    activitiesSubtitle() {
      if (this.disabled) {
        return this.$t("job_applications.activities.non_editable", {'status': this.jobApplication?.status[1]})
      }
      return this.$t("job_applications.activities.editable", {'status': this.jobApplication?.status[1]})
    }
  },
  mounted() {
    this.setManualDataIsLoading(true)
    this.getImplicitActivities().then(() => {
      // Getting ~700 professions is quite slow, so do only on full refresh
      if (this.professions.length === 0) {
        this.getProfessions().then(() => {
          this.refreshJobApplication()
        })
      } else {
        this.refreshJobApplication()
      }
    })
  },
  methods: {
    ...mapActions('candidates', ['getCandidate', 'unselectCandidate']),
    ...mapActions('professions', ['getProfessions']),
    ...mapActions('activities', [
      'getImplicitActivities',
      'setInitialSelectedExplicitActivities',
      'createJobApplicationActivitySelection',
      'removeJobApplicationActivitySelection',
      'removeJobApplicationActivitySelections',
    ]),
    ...mapActions('jobApplications', ['getJobApplication']),
    ...mapMutations('page', ['setManualDataIsLoading']),
    refreshJobApplication() {
      this.getJobApplication(this.jobApplicationId).then(() => {
        this.getCandidate(this.jobApplication.candidate.id).then(() => {
          this.setInitialSelectedExplicitActivities(
            this.jobApplicationActivitySelections
          )
          this.setManualDataIsLoading(false)
        })
      })
    },
    addAndRemoveActivitySelections(addedActivities, removedSelections) {
      this.createJobApplicationActivitySelection({
        "items": addedActivities.map(activity =>
          ({
            activity_id: activity,
            interest: 1,
            job_application_id: this.jobApplication.id
          })
        )
      }).then(() => {
        this.removeJobApplicationActivitySelections(
          removedSelections.map(selection => selection.id)
        ).then(() => {
          this.refreshJobApplication()
          eventBus.$emit('closeDialog')
        })
      })
    },
    removeSelection(selectionId) {
      this.removeJobApplicationActivitySelection(selectionId).then(() => {
        this.refreshJobApplication()
        eventBus.$emit('closeDeleteDialog')
      })
    }
  }
}
</script>

<style scoped></style>
