<template>
  <div>
    <v-checkbox
      v-model="filterValue"
      color="primary"
      :false-value="all"
      :true-value="qualified"
      dense
    >
      <template v-slot:label>
        <span v-html="$t('listing.matches.filters.qualified_label')"></span>
      </template>
    </v-checkbox>
  </div>
</template>
<script>
import { QualificationFilterValues } from "@/helpers/qualificationFilter";

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      filterValue: this.value,
      qualified: QualificationFilterValues.Qualified,
      all: QualificationFilterValues.All
    }
  },
  watch: {
    filterValue:{
      handler() {
        this.$emit('input', this.filterValue)
      }
    },
    value: {
      handler() {
        this.filterValue = this.value
      }
    }
  }
}
</script>
<style scoped></style>
