import Representative from "@/models/Representative.model";
import Employer from "@/models/Employer.model";
import Address, {AddressData} from "@/models/Address.model";

export default function JobOffer(data) {
  this.id = data.id || null
  this.name = data.name || null
  this.address = (data.address) ? new Address(data.address) : null
  this.description = data.description || null
  this.minimumSalary = data.minimum_salary || null
  this.contractDuration = data.contract_duration || null
  this.contractType = data.contract_duration == null ? 1 : 0;
  this.criminalRecordExtractNeeded = data.criminal_record_extract_needed || false
  this.rate = data.rate || null
  this.availableFrom = data.available_from || null
  this.irregularlyHours = data.irregularly_hours || false
  this.remotePossible = data.remote_possible || false
  this.drivingLicenseNeeded = data.driving_license_needed || false
  this.vehicleNeeded = data.vehicle_needed || false
  this.comment = data.comment || null
  this.workingPeriods = data.working_periods || null
  this.selections = data.selections || []

  this.employerRepresentative = new Representative(data.employer_representative) || null
  this.employer = new Employer(data.employer_representative.employer) || null

  this.reservedByName = data.reserved_by_name || null
  this.reservedMatchId = data.reserved_match_id || null
  this.reservedAt = data.reserved_at || null
  this.status = data.status || null
  this.assignedUserName = data.assigned_user_name || null
}

export function JobOfferData(jobOffer) {
  this.id = jobOffer.id || null
  this.name = jobOffer.name || null
  this.address = new AddressData(jobOffer.address)
  this.description = jobOffer.description || null
  this.minimum_salary = jobOffer.minimumSalary || null
  this.contract_duration = jobOffer.contractDuration || null
  this.criminal_record_extract_needed = jobOffer.criminalRecordExtractNeeded || false

  this.employer_representative_id = jobOffer.employerRepresentative.id || null
  this.rate = jobOffer.rate || null

  this.available_from = jobOffer.availableFrom || null
  this.irregularly_hours = jobOffer.irregularlyHours || false
  this.remote_possible = jobOffer.remotePossible || false

  this.driving_license_needed = jobOffer.drivingLicenseNeeded || false
  this.vehicle_needed = jobOffer.vehicleNeeded || false
  this.comment = jobOffer.comment || null
  this.working_periods = jobOffer.workingPeriods || null

  // Other fields available (not used yet)
  // this.assigned_user = JobOfferData.assigned_user || null
  // this.created_at = JobOfferData.created_at || null
  // this.updated_at = JobOfferData.updated_at || null
}

export function JobOfferVersion(data) {
  this.id = data.id
  this.startAt = data.start_at
}
