<template>
  <v-dialog v-model="dialog" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-show="!dataIsLoading" :disabled="disabled" color="primary" v-bind="attrs" v-on="on">
        {{ $t('activities.edit_activities') }}
        <v-icon dark> mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card color="background" class="pa-8" data-cy="edit_activities_dialog">
      <v-card-title class="text-h5">
        {{ $t('activities.modal.title') }}
      </v-card-title>
      <v-card-text style="height: 50rem">
        <v-row class="fill-height">
          <v-col cols="6" data-cy="available_activities_column">
            <v-card height="100%" class="pt-3" rounded="false">
              <v-autocomplete
                v-model="profession"
                class="px-2"
                :items="professionsArray"
                item-value="id"
                item-text="name"
                :filter="filterProfessions"
                prepend-icon="mdi-filter"
                :placeholder="
                  $t('activities.modal.filters.profession.placeholder')
                "
                data-cy="search_by_profession_autocomplete"
                clearable
                @change="handleSearch"
              >
              </v-autocomplete>
              <v-text-field
                v-model="search"
                class="px-2"
                prepend-icon="mdi-magnify"
                :placeholder="$t('activities.modal.filters.text.placeholder')"
              ></v-text-field>
              <AvailableActivities
                :search-keywords="search"
                @new="activityCreated"
              />
            </v-card>
          </v-col>
          <v-col cols="6" data-cy="selected_activities_column">
            <SelectedActivities></SelectedActivities>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>
        <v-btn @click="cancel">
          {{ $t('global.cancel') }}
        </v-btn>
        <v-btn color="primary" data-cy="save_btn" @click="save">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import AvailableActivities from '@/components/activities/AvailableActivities.vue'
import SelectedActivities from '@/components/activities/SelectedActivities.vue'
import eventBus from '@/eventBus'
import unaccented from "@/helpers/texts";

export default {
  name: 'EditActivitiesDialog',
  components: { AvailableActivities, SelectedActivities },
  props: {
    previousSelection: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      profession: null,
      search: null,
      debounceFn: null
    }
  },
  computed: {
    ...mapState('professions', ['professions']),
    ...mapGetters('page', ['dataIsLoading']),
    ...mapState('activities', ['selectedExplicitActivities']),
    professionsArray() {
      return Object.values(this.professions)
    }
  },
  watch: {
    search(value) {
      if (value !== null) this.debounceFn()
    }
  },
  mounted() {
    this.debounceFn = debounce(this.handleSearch, 666) // Mouhahahaha
    eventBus.$on('closeDialog', this.closeDialog)
  },
  methods: {
    ...mapActions('activities', [
      'addActivityToSelection',
      'getExplicitActivities',
      'resetExplicitActivities'
    ]),
    filterProfessions(item, queryText) {
      // Unaccent & Lowercase
      const normalizedItem = unaccented(item.name.toLocaleLowerCase())
      const normalizedQuery = unaccented(queryText.toLocaleLowerCase())

      return (
        normalizedItem.indexOf(normalizedQuery) > -1 ||
        item.synonyms.find(
          (s) => unaccented(s.toLocaleLowerCase()).indexOf(normalizedQuery) > -1
        ) !== undefined
      )
    },
    activityCreated(data) {
      this.addActivityToSelection(data)
      // FIXME new handleSearch here is meaningful only if all the linguistics has been recomputed server side.
      // this.handleSearch()
    },
    handleSearch() {
      this.getExplicitActivities({
        professionId: this.profession,
        searchTerms: this.search
      })
    },
    cancel() {
      this.closeDialog()
    },
    closeDialog() {
      this.search = null
      this.profession = null
      this.dialog = false
      this.resetExplicitActivities()
    },
    save() {
      const selectedActivities = Object.values(
        this.selectedExplicitActivities
      ).map(a => a.id)
      const addedActivities = selectedActivities.filter(
        a => !this.previousSelection.map(s => s.activity.id).includes(a)
      )
      const removedSelections = this.previousSelection.filter(
        s => !selectedActivities.includes(s.activity.id)
      )
      this.$emit(
        'addAndRemoveActivitySelections',
        addedActivities,
        removedSelections
      )
    }
  }
}
</script>

<style scoped></style>
