<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="light"
        icon
        data-cy="edit_activity_name_btn"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ iconSelection(item) }}</v-icon>
      </v-btn>
    </template>

    <v-card color="background" class="pa-8" data-cy="edit_activity_name_dialog">

      <v-card-title class="text-h5" style="word-break: break-word">
        {{ $t('activities.context.title') }}
      </v-card-title>

      <v-card-text>
        <h3>{{ $t('activities.context.original') }}</h3>
        <div>{{ item.activity.name }}</div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="mx-auto"
          small
          @click="copyDisplayName"
        >
          <v-icon>mdi-content-copy</v-icon>
          {{ $t('activities.context.copy_original') }}
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <h3>{{ $t('activities.context.custom') }}</h3>
        <v-textarea
          v-model="customName"
          data-cy="edit_activity_name_text_area"
        ></v-textarea>
      </v-card-text>

      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>
        <v-btn @click="cancel">
          {{ $t('global.cancel') }}
        </v-btn>
        <v-btn color="primary" data-cy="save_activity_name_btn" @click="save">
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { Variant } from '@/components/activities/ExplicitActivitiesTable.vue'

export default {
  name: 'EditActivityNameDialog',
  props: {
    item: {
      type: Object,
      required: true
    },
    activitySelections: {
      type: Array,
      required: false,
      default: null
    },
    variant: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      customName: this.item.contextualized_name
    }
  },
  methods: {
    ...mapActions('jobOffers', ['getJobOffer']),
    ...mapActions('jobApplications', ['getJobApplication']),
    ...mapActions('activities', [
      'updateJobOfferActivitySelection',
      'updateJobApplicationActivitySelection',
      'setInitialSelectedExplicitActivities'
    ]),
    iconSelection(item) {
      return item.is_name_overriden ? 'mdi-eye' : 'mdi-pencil'
    },
    copyDisplayName() {
      this.customName = this.item.display_name
    },
    cancel() {
      this.customName = this.item.contextualized_name
      this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
    },
    save() {
      let updateFunction
      let idArg
      let callback

      if (this.variant === Variant.Offer) {
        updateFunction = this.updateJobOfferActivitySelection
        idArg = {job_offer_id: this.item.job_offer_id}
        callback = this.getJobOffer
      } else {
        updateFunction = this.updateJobApplicationActivitySelection
        idArg = {job_application_id: this.item.job_application_id}
        callback = this.getJobApplication
      }
      updateFunction({
        id: this.item.id,
        data: {
          contextualized_name: this.customName,
          ...idArg
        }
      }).then(() => {
        callback(this.$route.params.id).then(() => {
          this.setInitialSelectedExplicitActivities(
            this.activitySelections
          )
        })
        this.closeDialog()
      })
    }
  }
}
</script>

<style scoped></style>
