/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import Vue from 'vue'
import {
  getJobOffers,
  getJobOffer,
  createJobOffer,
  updateJobOffer,
  updateJobOfferStatus
} from '@/services/jobOffers'

export const state = {
  jobOffer: null,
  jobOffers: {
    count: null,
    next: null,
    previous: null,
    items: [],
  },
  storedFilters: {}
}

export const mutations = {
  setJobOffers(state, jobOffers) {
    let results;
    if ("results" in jobOffers) {
      // Paginated
      results = jobOffers.results
      state.jobOffers.count = jobOffers.count
      state.jobOffers.next = jobOffers.next
      state.jobOffers.previous = jobOffers.previous
    } else {
      // Full result non paginated
      results = jobOffers
    }

    state.jobOffers.items = []
    results.forEach((offer, index) => {
      // FIXME Why not creating a JS Object from offer ?
      Vue.set(state.jobOffers.items, index, offer)
    })
  },
  setFilters(state, args) {
    state.storedFilters.search = args.search
    if ('mine' in args) {
      state.storedFilters.mine = args.mine
    } else {
      delete state.storedFilters.mine
    }
  },
  setJobOffer(state, jobOffer) {
    state.jobOffer = jobOffer
  },
  setJobOfferStatus(state, status) {
    state.jobOffer.status = status
  },
  setRepresentative(state, representative) {
    if (state.jobOffer === null) {
      state.jobOffer = {};
    }
    state.jobOffer.employerRepresentative = representative
  },
  setImplicitActivity(state, data) {
    state.jobOffer.selections.forEach((selection) => {
      if (selection.activity.id === data.explicitActivityId) {
        // eslint-disable-next-line no-param-reassign
        selection.activity.implicit_activity = data.implicitActivity;
      }
    });
  },
  updateSingleSelectedActivity(state, activitySelection) {
    const storedSelection = state.jobOffer.selections.filter(
      selection => selection.id === activitySelection.id
    )[0];
    storedSelection.effort = activitySelection.effort;
    storedSelection.frequency = activitySelection.frequency;
  }
}

export const actions = {
  getJobOffers({ state, commit }, args) {
    return new Promise((resolve) => {
      getJobOffers(args).then(response => {
        commit('setJobOffers', response.data)
        commit('setFilters', args)
        resolve(state.jobOffers);
      })
    });
  },
  getJobOffer({ commit, dispatch }, offerId) {
    return new Promise((resolve, reject) => {
      getJobOffer(offerId)
        .then(jobOffer => {
          // The jobOffer
          commit('setJobOffer', jobOffer)
          // Save the current employer already given in the jobOffer
          commit(
            'employers/setEmployer',
            jobOffer.employer, // Already mapped to object by service.getJobOffer
            { root: true }
          )
          // Get (async) and add the representatives in the store
          dispatch(
            'employers/getRepresentatives',
            jobOffer.employerRepresentative.employer.id,
            { root: true }
          )
          resolve()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // TODO
          reject(error)
        })
    })
  },
  unselectJobOffer({ commit }) {
    commit('setJobOffer', null)
  },
  createJobOffer({ commit }, form) {
    return new Promise((resolve, reject) => {
      createJobOffer(form).then(createdJobOffer => {
        commit('setJobOffer', createdJobOffer)
        resolve(createdJobOffer)
      })
      .catch(error => {
        reject(error)
      })
    })

  },
  updateJobOffer({ commit }, jobOffer) {
    return updateJobOffer(jobOffer).then(updatedJobOffer => {
      commit('setJobOffer', updatedJobOffer)
    })
  },
  updateJobOfferStatus({ state, commit }, status) {
    commit('page/setDataLoadingEnabled', false, {root: true})
    return new Promise((resolve, reject) => {
      updateJobOfferStatus(state.jobOffer.id, status).then(response => {
        commit('setJobOfferStatus', response.data.status)
        resolve()
      }).catch(error => {
        reject(error.response.data)
      }).finally(
        commit('page/setDataLoadingEnabled', true, {root: true})
      )
    })
  }
}

export const getters = {
  getJobOfferAddress(state) {
    // Return the address only if it has been saved
    return (
      state.jobOffer === null
      || state.jobOffer.address === null
    ) ? null : state.jobOffer.address
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
