function unaccented(str) {
  /**
   * Remove all "diacritical" characters.
   * normalize("NFD"): ê => e^
   * replace regex: e^ => e
   */
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export default unaccented
