<template>
  <v-row
    v-if="!loading"
    class="training-level"
  >
    <span
      v-if="readonly"
      class="training-level--centered"
    >
      {{ effort }}
    </span>
    <v-text-field
      v-else
      v-model="effort"
      type="number"
      :min="min"
      :max="max"
      @blur="validateLevelAndSave"
    >
    </v-text-field>
  </v-row>
  <v-row v-else>
    <DataLoader :custom-loading-trigger="loading"></DataLoader>
  </v-row>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import DataLoader from '@/components/common/DataLoader.vue'

export default {
  name: 'TrainingLevel',
  components: { DataLoader },
  props: {
    selection: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // FIXME: Get min max from the effort scale in the API
      min: 1,
      max: 6,
      effort: 2,
      loading: false
    }
  },
  watch: {
    selection: {
      immediate: true,
      handler(selection) {
        this.effort = selection.effort
      }
    }
  },
  methods: {
    ...mapMutations('page', ['setDataLoadingEnabled']),
    ...mapActions('activities', ['updateJobOfferActivitySelection']),
    validateLevelAndSave() {
      if (this.readonly) return
      this.loading = true
      this.setDataLoadingEnabled(false)
      if (this.effort < this.min) this.effort = this.min
      if (this.effort > this.max) this.effort = this.max
      this.updateJobOfferActivitySelection({
        id: this.selection.id,
        data: {
          effort: this.effort,
          job_offer_id: this.selection.job_offer_id
        }
      }).finally(() => {
        this.setDataLoadingEnabled(true)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.training-level--centered {
  margin: 0 auto;
}
</style>
